*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'Proxima Nova'
  text-decoration: none
  font-weight: 400
  color: #fff
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box

body
  width: 100%
  height: 100%
  overflow: hidden

.widget-section
  width: 100%
  height: 100%
  .widget
    width: 100%
    height: 100%

.widget-instructions__hint
  white-space: pre-wrap

.widget-application .widget-logo img 
  width: 68px !important
  height: 68px !important

.widget-application .widget-instructions__hint p
  font-size: 12px !important
  
.widget-application--tablet.widget-application .widget-instructions__hint p
  font-size: 9px !important
  line-height: 10.55px !important




