*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Proxima Nova;
  font-weight: 400;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-section, .widget-section .widget {
  width: 100%;
  height: 100%;
}

.widget-instructions__hint {
  white-space: pre-wrap;
}

.widget-application .widget-logo img {
  width: 68px !important;
  height: 68px !important;
}

.widget-application .widget-instructions__hint p {
  font-size: 12px !important;
}

.widget-application--tablet.widget-application .widget-instructions__hint p {
  font-size: 9px !important;
  line-height: 10.55px !important;
}
/*# sourceMappingURL=index.ce9f6c1f.css.map */
